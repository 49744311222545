const MODE_KEY = "appMode";
const USER_KEY = "appUser";

// Function to set the mode in localStorage
export const setMode = (mode) => {
  localStorage.setItem(MODE_KEY, mode);
};

// Function to get the mode from localStorage
export const getMode = () => {
  return localStorage.getItem(MODE_KEY) || "dark-mode";
};

// Function to set the user in localStorage
export const setUser = (user) => {
  localStorage.setItem(USER_KEY, user);
};

// Function to get the user from localStorage
export const getUser = () => {
  return localStorage.getItem(USER_KEY) || "";
};

export const setToken = (token) => {
  localStorage.removeItem("token");
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};
export const setAdminAccessToken = (token) => {
  localStorage.removeItem("adminToken");
  localStorage.setItem("adminToken", token);
};

export const getAdminAccessToken = () => {
  return localStorage.getItem("adminToken");
};

export const removeAdminAccessToken = () => {
  return localStorage.removeItem("adminToken");
};
export const removeToken = () => {
  return localStorage.removeItem("token");
};

export const removeUserObject = () => {
  return localStorage.removeItem("user");
};
export const removeAccountObject = () => {
  return localStorage.removeItem("account");
};
export const removeAccounts = () => {
  return localStorage.removeItem("accounts");
};
export const removeDistributors = () => {
  return localStorage.removeItem("distributors");
};

export const setAccountObject = (account) => {
  let { name, username, email, phone_number, unique_id, is_email_authorised } = account;
  const accountData = { name, username, email, phone_number, unique_id, is_email_authorised };
  localStorage.removeItem("account");
  localStorage.setItem("account", JSON.stringify(accountData));
};

export const setAccounts = (accounts) => {
  localStorage.removeItem("accounts");
  localStorage.setItem("accounts", JSON.stringify(accounts));
};

export const getAccounts = () => {
  const accounts = localStorage.getItem("accounts");
  return JSON.parse(accounts);
};
export const setDistributors = (distributors) => {
  localStorage.removeItem("distributors");
  localStorage.setItem("distributors", JSON.stringify(distributors));
};

export const getDistributors = () => {
  const distributors = localStorage.getItem("distributors");
  if (distributors && distributors !== "undefined") {
    return JSON.parse(distributors);
  } else {
    return null;
  }
};

export const setAccountObj = (user) => {
  localStorage.removeItem("user");
  localStorage.setItem("user", JSON.stringify(user));
};


export const getAccountObject = () => {
  const account = localStorage.getItem("account");
  if (account && account !== "undefined") {
    return JSON.parse(account);
  } else {
    return null;
  }
};
