export const BaseUrl =
  process.env.VUE_APP_BASE_URL || "https://www.be.autom8games.com";

export const ACCOUNT = "/account";
export const DISTRIBUTOR = "/distributor";

// accounts end points
export const GET_ACCOUNTS = `${ACCOUNT}/get_accounts`;
export const GET_AGENTS_STATS = `${ACCOUNT}/get_agents_stats`;
export const ADMIN_SECRET = `${ACCOUNT}/admin_secret`;

// distributor end points
export const GET_DISTRIBUTORS = `${DISTRIBUTOR}/get_distributors`;
export const GET_DISTRIBUTOR_ACCOUNT_STATS = `${DISTRIBUTOR}/get_distributor_account_stats`;
