<template>
  <div class="page">
      <div class="inner-box" :class="{'is-agent': isAgentData}">
        <DynamicTable
          :showSelected="true"
          :showFooter="false"
          :isLoading="loading"
          :columns="tableColumns"
          :data="tableData"
          :showArrow = "true"
          :account = "account"
          :isAccount = "true"
        />
      </div>
  </div>
</template>

<script>
import DynamicTable from "@/components/Table.vue";
import {
} from "@/utils/api-routes";
import { getAccounts } from "@/services/localStorage";

export default {
  name: "accountList",
  components: {
    DynamicTable,
  },
  data() {
    return {
      tableColumns: [
        // { key: "email", label: "Email" },
        { key: "username", label: "Username" },
        { key: "credit_last_thirty", label: "Last Thirty (Days)" },
      ],
      tableData: [],
      loading: false,
      account: {
        username: "",
        credit_last_thirty: "",
      },
      errorMsg: "",
      isAgentData: false,
      accountsData: [],
      unique_id: "",
    };
  },
  created() {
    this.getAccountsData();
  },
  methods: {
    getAccountsData() {
      this.tableData = getAccounts();
      this.accountsData = this.tableData;
      console.log('accounts: ', this.tableData);
    }
  },
};
</script>
<style scoped lang="scss">
.page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0px 20px 0px;

  .inner-box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .is-agent {
    width: 100%;
  }

  .foot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .errorMsg {
      font-size: 22px;
      color: #ff8a96;
      letter-spacing: 0.5px;
      font-weight: 600;
    }
  }

  .unit {
    display: flex;
    align-items: center;
    margin: 30px 0px;

    label {
      font-size: 25px;
      font-weight: 500;
      width: 200px;
    }
  }

  .select {
    padding: 10px;
    border: none;
    width: 300px;
    border-radius: 3px;
    color: #000000b0;

    &::placeholder {
      color: var(--input-placeholder);
      opacity: 0.3;
    }

    &:focus {
      outline: none;
      border-color: white;
    }
  }
}

@media (max-width: 768px) {
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    .inner-box {
      height: 100%;
      width: 100%;
      display: block;
      // justify-content: center;
    }
  }
  .unit {
    label {
      font-size: 15px !important;
    }
  }
}
</style>