import axios from "axios";
import { BaseUrl } from "@/utils/api-routes";
import { removeToken, removeUserObject } from "@/services/localStorage";

const httpService = () => {
  const token = localStorage.getItem("adminToken");
  let headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods":
      "GET, POST, HEAD, OPTIONS, PUT, DELETE, PATCH",
    "Access-Control-Allow-Headers":
      "Content-Type, X-Amz-Date, Authorization, X-Api-Key, X-Amz-Security-Token, Origin",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const instance = axios.create({
    baseURL: BaseUrl,
    headers,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const {
        message,
        response: { status, data },
      } = error;
      if (status === 401) {
        console.error(message, data);
        removeToken();
        removeUserObject();
        window.location.reload();
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const get = async (url, config) => {
  const response = await httpService().get(url, config);
  return response.data;
};

export const post = async (url, body, config) => {
  const response = await httpService().post(url, body, config);
  return response.data;
};

export const put = async (url, body, config) => {
  const response = await httpService().put(url, body, config);
  return response.data;
};

export const patch = async (url, body) => {
  const response = await httpService().patch(url, body);
  return response.data;
};

export const del = async (url, body) => {
  const response = await httpService().delete(url, body);
  return response.data;
};

export const setHttpParams = (params) => {
  let url = "";
  if (params) {
    const limit = params.limit !== 0 ? `&limit=${params.limit}` : "";
    url = `?start=${params.start}${limit}${
      params.attribute &&
      "&order_by=" + params.attribute + ":" + params.order_by
    }${params.status && "&status=" + params.status}`;
  }
  return url;
};
