import { createRouter, createWebHistory } from "vue-router";
import Admin from "./pages/admin.vue";
import Callback from "./pages/Callback.vue";
import AccountTable from "./pages/AccountTable.vue";
import DistributorTable from "./pages/DistributorTable.vue";
import accountDetails from "./pages/AccountDetails.vue";
import Privacy from "./pages/Privacy.vue";

const routes = [
  {
    path: "",
    redirect: { name: "admin" },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: { title: "admin" },
  },
  {
    path: "/accounts",
    name: "accountList",
    component: AccountTable,
    meta: { title: "account" },
  },
  {
    path: "/distributors",
    name: "distributorList",
    component: DistributorTable,
    meta: { title: "distributor" },
  },
  {
    path: "/accounts/:id",
    name: "accountDetails",
    component: accountDetails,
    meta: { title: "accountDetails" },
  },
  {
    path: "/callback",
    name: "callback",
    component: Callback,
    meta: { title: "callback" },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    meta: { title: "privacy" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = localStorage.getItem("token") !== null;

//   // Redirect to 'home' or 'login' when accessing the base URL
//   if (to.path === '/' || to.path === '') {
//     next(isAuthenticated ? { name: 'home' } : { name: 'login' });
//     return;
//   }

//   // If authenticated, prevent access to 'login', 'signup', 'signin'
//   if (isAuthenticated && (to.name === 'login' || to.name === 'signup' || to.name === 'signin')) {
//     next(from.name ? undefined : { name: 'home' });
//     return;
//   }

//   // If not authenticated, redirect to 'login' from protected routes
//   if (!isAuthenticated && to.meta.requiresAuth) {
//     next({ name: 'login' });
//     return;
//   }

//   // Proceed with the navigation in all other cases
//   next();
// });

export default router;
