<template>
  <div class="p">
    <div class="secret-key-container">
      <div class="form-group">
        <label
          class="g-font gl-color"
          :class="{ 'dark-gl-color': !darkMode }"
          for="email"
        >
          Secret Key</label
        >
        <input
          class="form-control g-font"
          :class="{ 'dark-placeholder': !darkMode }"
          placeholder="Enter scret key"
          id="secretKey"
          type="password"
          v-model="secretKey"
        />
      </div>
      <div v-if="errorMsg" class="error-msg on-center">{{ errorMsg }}</div>
      <button
        type="submit"
        :disabled="formSubmitted"
        class="submit-button g-font"
        @click="getReports"
      >
        Get Reports
      </button>
    </div>
    <div class="content">
      <h4>Autom8 works 24/7, so YOU don't have to!</h4>
      <div class="inner-content">
        <div class="left">
          <span> <img src="@/assets/images/check.png" /> Fast and Secure!</span>
          <span> <img src="@/assets/images/check.png" />24/7 Tech Support</span>
          <span>
            <img src="@/assets/images/check.png" />Utmost respect for
            privacy!</span
          >
        </div>
      </div>
    </div>
    <div class="p-sponsor">
      <h4>Optimized For Sponsors!</h4>
      <div class="sponsor">
        <div class="img">
          <img src="@/assets/images/dragobworld.png" />
        </div>
        <div class="img">
          <img src="@/assets/images/dragon_crown.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMode, setAdminAccessToken } from "@/services/localStorage";
const checkIfDark = (name) => name === "dark-mode";
import { get } from "@/services/http";
import { ADMIN_SECRET } from "@/utils/api-routes";

export default {
  name: "AdminLogin",
  data() {
    return {
      darkMode: checkIfDark(getMode()),
      errorMsg: "",
      isLoading: false,
      secretKey: "",
      formSubmitted: false,
    };
  },
  created() {
    this.emitter.on("mode-change", (data) => {
      this.darkMode = checkIfDark(data.mode);
    });
  },
  methods: {
    async getReports() {
      console.log("this.secretkey: ", this.secretKey);
      const url = ADMIN_SECRET + `?secret_key=${this.secretKey}`;
      try {
        this.formSubmitted = true;
        const response = await get(url);
        const { access_token } = response;
        console.log("access_token: ", access_token);
        setAdminAccessToken(access_token);
        this.$router.push("/distributors");
      } catch (err) {
        this.formSubmitted = false;
        this.errorMsg = err.response.data.detail;
        this.$router.push("/");
        console.log("err ==> ", err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.p {
  height: calc(100vh - 250px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none;
}
.p::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.p-sponsor {
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.sponsor {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .img {
    background: #ffffff59;
    padding: 20px;
    border-radius: 50%;
    height: 130px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    img {
      width: 80%;
    }
  }
}

.secret-key-container {
  height: 60%;
  width: 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 2px solid #353580;
  padding: 10px;
  border-radius: 4px;
  margin-top: 7vh;
  min-height: 250px;
}

.txt-cl {
  text-decoration: none;
  color: #242460;
  font-weight: bold;
}
.is-dark {
  text-decoration: none;
  font-weight: bold;
  color: #1c7cc9;
}

.g-font {
  font-family: "Lato", sans-serif;
}

.dark-placeholder::placeholder {
  color: #242460; /* Dark mode placeholder color */
}

.form-control {
  border: 1px solid #353580;
  background: transparent;
  border-radius: 4px;
  color: white;
}
.form-group {
  width: 70%;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.error-msg {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.on-center {
  display: flex;
  justify-content: center;
}

.submit-button {
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 70%;
  font-size: 1em;
  border-radius: 4px;
  background-color: #242460;
  margin-top: 2vh;
}

.submit-button:disabled {
  /* background-color: rgb(0, 172, 193, 0.11); */
  background-color: #242460;
  pointer-events: none;
}
.submit-button:hover {
  background-color: rgba(255, 255, 255, 0.11);
}
.gl-color {
  color: #1c7cc9;
  font-weight: 600;
}
.dark-gl-color {
  color: #242460;
  font-weight: 600;
}

.content {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  .inner-content {
    display: flex;
  }
  .left {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    span {
      display: flex;
      align-items: center;
      margin: 5px 15px;
      img {
        height: 24px;
        width: 24px;
        margin: 0px 05px 0px 0px;
      }
    }
  }
}

@media (min-width: 320px) {
  .content {
    h4 {
      text-align: center;
    }

    .right {
      button {
        font-size: 16px;
        width: 70px;
        img {
          width: 30px;
        }
      }
    }
  }
}

@media (min-width: 480px) {
  .content {
    h4 {
      text-align: center;
    }

    .right {
      button {
        font-size: 16px;
        width: 70px;
        img {
          width: 30px;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .content {
    h4 {
      text-align: center;
    }

    .right {
      display: flex;
      justify-content: center;
    }
  }
}
@media (min-width: 1024px) {
  .content {
    h4 {
      margin-bottom: 5px;
    }
  }
}

@media (min-width: 1400px) {
}

@media (max-width: 768px) {
  .secret-key-container {
    width: 80%;
    margin-bottom: 10px;
  }
}

@media (max-width: 568px) {
  .sponsor {
    flex-direction: column;
    .img {
      height: 80px;
      height: 100px;
      padding: 10px;
    }
  }
}
</style>
