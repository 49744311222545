<template>
  <nav :class="[{ 'dark-m': this.darkMode }]">
    <div class="left">
      <!-- Button that when clicked will call the redirectToTelegram method -->
      <button @click="redirectToTelegram">
        <img
          height="30"
          :src="require('@/assets/images/telegram.png')"
          alt="Telegram Icon"
        >
      </button>
    </div>
    <div class="centre" :class="{ 'ml-110': isHomeOrLogin }" style="cursor: pointer;" @click="goToLogin">
      <img src="@/assets/images/Autom-logo.png" width="100" />
      <h1>Autom8</h1>
    </div>
    <div class="right">
      <div class="tdnn-parent">
        <div @click="toggleMode" :class="['tdnn', { day: darkMode }]">
          <div :class="['moon', { sun: darkMode }]"></div>
        </div>
      </div>
      <img
        v-if="!darkMode && userLoggedIn"
        class="logout"
        @click="logOut()"
        src="@/assets/logout.svg"
        alt=""
      />
      <img
        v-if="darkMode && userLoggedIn"
        class="logout"
        @click="logOut()"
        src="@/assets/logout-dark.svg"
        alt=""
      />
    </div>
  </nav>
</template>

<script>
import { getMode } from "@/services/localStorage";
import {
  removeAdminAccessToken,
  removeAccountObject,
  getAdminAccessToken,
  removeDistributors,
  removeAccounts
} from "@/services/localStorage";

const getModeName = (dark) => (dark ? "dark-mode" : "light-mode");
const checkIfDark = (name) => name === "dark-mode";

export default {
  name: "NavBar",
  data() {
    return {
      darkMode: checkIfDark(getMode()),
      currentPath: "",
      userLoggedIn: getAdminAccessToken() !== null,
    };
  },
  computed: {
    isHomeOrLogin() {
      return this.currentPath === "/home" || this.currentPath === "/login";
    },
  },
  watch: {
    $route({ name }) {
      this.currentPath = name;
      this.userLoggedIn = getAdminAccessToken() !== null;
    },
  },
  methods: {
    redirectToTelegram() {
      // Redirect to the Telegram chat
      window.location.href = 'https://t.me/Autom8games';
    },
    toggleMode() {
      this.darkMode = !this.darkMode;
      this.emitter.emit("mode-change", { mode: getModeName(this.darkMode) });
    },
    logOut() {
      removeAdminAccessToken();
      removeAccountObject();
      removeAccounts();
      removeDistributors();
      this.$router.push({ name: "admin" });
    },
    openGooglePopup() {
      this.emitter.emit("open-google-popup");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logout {
    height: 25px;
    cursor: pointer;

    &:hover {
      transition: ease-in-out 0.2s;
      transform: scale(1.2);
    }
  }
}
.home-icon {
  margin-left: 20px;
  margin-top: 6px;
}
.left {
  display: flex;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    img {
      height: 30px;
    }
  }
}

.left,
.right {
  width: 33%;
}

.right {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: end;
  button {
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: transparent;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 35px;
    transition: background-color 0.3s ease;
    padding: 10px;
    border-radius: 50px;

    &:hover {
      background-color: rgb(255 255 255 / 11%);
    }
    img {
      height: 30px;
    }
    span {
      background-image: linear-gradient(
        60deg,
        rgb(183, 167, 248) 0%,
        white 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 700;
      font-size: 16px;
    }
    .sign-up-txt {
      font-weight: 700;
      font-size: 16px;
      color: #0c0c28;
      text-decoration: none;
    }

    .dark-txt-color {
      font-weight: 700;
      font-size: 16px;
      color: #2196f3;
      text-decoration: none;
    }
    .telegram {
      background-color: transparent !important;
      border: none !important;
    }
  }
}

.centre {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  &.ml-110 {
    margin-left: 125px;
  }
  h1 {
    margin: 0;
    background-image: linear-gradient(60deg, rgb(183, 167, 248) 0%, white 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 700;
  }
}

.dark-m {
  .centre {
    h1 {
      background-image: linear-gradient(
        60deg,
        rgb(84, 58, 183) 0%,
        rgb(0, 172, 193) 100%
      );
    }
  }
}

.je {
  justify-content: end !important;
}

.tdnn-parent {
  position: relative;
}

.tdnn {
  transform: scale(0.3);
  margin-left: 0 auto;
  font-size: 30%;
  position: relative;
  height: var(--toggleHeight);
  width: var(--toggleWidth);
  border-radius: var(--toggleHeight);
  transition: all 500ms ease-in-out;
  background: var(--bgColor--night);
  cursor: pointer;
}

.day {
  background: rgb(68, 45, 153);
}

.moon {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
  top: 3em;
  left: 3em;
  transform: rotate(-75deg);
  width: var(--toggleBtnRadius);
  height: var(--toggleBtnRadius);
  background: var(--bgColor--night);
  box-shadow: 3em 2.5em 0 0em var(--mooncolor) inset,
    rgba(255, 255, 255, 0.1) 0em -7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 3em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 2em 13em 0 -4em,
    rgba(255, 255, 255, 0.1) 6em 2em 0 -4.1em,
    rgba(255, 255, 255, 0.1) 8em 8em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 6em 13em 0 -4.5em,
    rgba(255, 255, 255, 0.1) -4em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) -1em 10em 0 -4.5em;
}

.sun {
  top: 4.5em;
  left: 18em;
  transform: rotate(0deg);
  width: 7em;
  height: 7em;
  background: #fff;
  box-shadow: 3em 3em 0 5em #fff inset, 0 -5em 0 -2.7em #fff,
    3.5em -3.5em 0 -3em #fff, 5em 0 0 -2.7em #fff, 3.5em 3.5em 0 -3em #fff,
    0 5em 0 -2.7em #fff, -3.5em 3.5em 0 -3em #fff, -5em 0 0 -2.7em #fff,
    -3.5em -3.5em 0 -3em #fff;
}

@media (max-width: 768px) {
  a {
    font-size: 16px;
    font-weight: 300;
  }

  .left,
  .right {
    width: 100px;
  }

  .right {
    button {
      span {
        margin-right: -40px;
      }
    }
  }
  .centre {
    h1 {
      display: none;
    }
    img {
      width: 80px;
    }
    &.ml-110 {
      margin-left: 0px;
    }
  }
  .right {
    justify-content: end;
  }
  .tdnn {
    position: absolute;
    right: -30px;
    top: -40px;
    transform: scale(0.28);
  }
  .top-navigation {
    width: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .home-icon {
    margin-left: 10px;
  }
}
</style>