<template>
  <div class="table-containe">
  <div v-if="isAgentData || isAccount" class="agent-top-div">
    <div class="email-and-filters">
      <div v-if="isAgentData" class="agent-div">
        <div class="agent-email" >{{ account.username }}</div>
        <!-- <img class="auth-img" :src= "account.is_email_authorised ? authorized : unauthorized" /> -->
      </div>
      <div class="back-and-filters">
        <div class="back" @click="goBack" >
          <img class="rotateimg180" src="@/assets/arrow-right.png" />
          <span>Back</span>
        </div>
        <div v-if="isAgentData" class="start-end-dates">
          <div class="start-date">
            <p>Start Date:</p>
            <datepicker v-model="startDate" format="yyyy-MM-dd" input-class ="my-picker-class"
            placeholder="Select Date"></datepicker>
          </div>
          <div class="end-date">
            <p>End Date:</p>
            <datepicker v-model="endDate" format="yyyy-MM-dd" input-class ="my-picker-class"
            placeholder="Select Date"></datepicker>
          </div>
          <button type="submit" class="submit-button g-font" @click="filterResults">Filter</button>
          <button type="submit" class="submit-button g-font" @click="clearFilters">Clear</button>
        </div>
      </div>
    </div>
  </div>
  <div :class="['dynamic-table', this.mode]">
      <div>
        <div class="table-header">
          <div
            v-for="column in columns"
            :key="column.key"
            :class="getHeaderClasses(column, index)"
            @click="column?.sortable && sortByColumn(column.key)"
          >
            {{ column.label }}
            <span v-if="column.sortable">
              <img
                class="arrow"
                v-if="this.mode === 'dark-mode'"
                src="@/assets/arrow-up-white.svg"
                :class="[
                  {
                    'arrow-down':
                      page.attribute === column.key && sortOrder === 'asc',
                  },
                ]"
                alt=""
              />
              <img
                v-else
                class="arrow"
                src="@/assets/arrow-up.svg"
                :class="[
                  {
                    'arrow-down':
                      page.attribute === column.key && sortOrder === 'asc',
                  },
                ]"
                alt=""
              />
            </span>
          </div>
          <div v-if="showDelete" class="delete-cell"></div>
          <div v-if="showArrow && !isDistributor" class="arrow-cell"></div>
        </div>
        <div class="body-cont">
          <div class="table-body">
          <div v-if="isLoading" class="loader-c">
            <div class="loader"></div>
          </div>
          <div v-else-if="data?.length === 0" class="loader-c">
            <h3>No Records Found</h3>
            <div>
              <img class="vibrating-element" src="@/assets/glass.svg" alt="" />
            </div>
          </div>
          <div
            v-else
            v-for="(item, index) in data"
            :key="item.id"
            @click="rowSelected(item)"
            class="table-row"
            :class="{ 'alternate-row': index % 2 === 1, 'disable-click': isAgentData }"
          >
            <div
              v-for="column in columns"
              :key="column.key"
              :title="item[column.key]"
              :class="getCellClasses(column, index)"
            >
              <div v-if="column.type === 'status'" class="statuses">
                <div :class="['tag', item[column.key]?.toLowerCase() + '-tag']">
                  {{ item[column.key] }}
                </div>
              </div>
              <span v-else-if="column.type === 'date'">
                {{ formattedDate(item[column.key]) }}
              </span>
              <span v-else-if="column.key === 'username'">
                {{ item[column.key].length < 4 ? item[column.key].substring(0, 1) + '***' : item[column.key].substring(0, 4) + '***' }}
              </span>
              <span v-else>
                {{ item[column.key] }}
              </span>
            </div>
            <div v-if="showDelete" class="delete-cell">
              <img
                @click.stop="rowDeleted(item)"
                src="@/assets/delete.svg"
                alt=""
              />
            </div>
            <div v-if="showArrow && !isAgentData" class="arrow-cell">
              <img
                @click.stop="rowSelected(item)"
                src="@/assets/arrow-right.png"
                alt=""
              />
            </div>
          </div>
          </div>
        </div>
        <div v-if="isAgentData && !isLoading" class="subtotal-row">
          <div v-for="column in columns" :key="column.key" :class="getCellClasses(column)">
            <div v-if="column.key === 'email'">Total</div>
              <div v-if="column.key !== 'email'">
                {{ calculateSubtotal(column.key) }}
              </div>
          </div>
        </div>
        <div v-if="isAccount && !isLoading" class="subtotal-row">
          <div v-for="column in columns" :key="column.key" :class="getCellClasses(column)">
            <div v-if="column.key === 'username'">Total</div>
              <div v-if="column.key !== 'username'">
                {{ calculateSubtotal(column.key) }}
              </div>
          </div>
        </div>
      </div>
  </div>
  </div>
  
</template>

<script>
import { getMode } from "@/services/localStorage";
import Datepicker from 'vue3-datepicker';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { setAccountObject, removeAccountObject, setAccounts } from "@/services/localStorage";

export default {
  name: "DynamicTable",
  props: {
    columns: Array,
    data: Array,
    account: Object,
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    pagination: Object,
    isLoading: {
      type: Boolean,
      default: false,
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    isAgentData: {
      type: Boolean,
      default: false,
    },
    isDistributor: {
      type: Boolean,
      default: false,
    },
    isAccount: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Datepicker,
  },
  setup() {
    const notify = (msg) => {
      toast(msg, {
        autoClose: 2000,
        type: 'info',
      });
    }
    return {
      notify
    };
  },
  data() {
    return {
      mode: "",
      sortOrder: "asc", // Default sort order
      selectedFilter: "",
      page: this.pagination,
      isSelected: false,
      authorized: require("@/assets/shield-fill-check.svg"),
      unauthorized: require("@/assets/shield-fill-x.svg"),
      startDate: null,
      endDate: null,
    };
  },
  watch: {
    selectedFilter(data) {
      this.$emit("filter", data);
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.data.length / this.itemsPerPage);
    },
    getCellClasses() {
      return (column, index) => {
        return {
          'table-cell': !this.isAgentData,
          'table-agent-cell': this.isAgentData || this.isDistributor,
          'sortable': column.sortable,
          'first-column': index === 0,
          'text-alignment': column.key === 'email' && this.isAgentData,
        };
      };
    },
    getHeaderClasses() {
      return (column, index) => {
        return {
          'table-cell': !this.isAgentData,
          'table-agent-cell': this.isAgentData || this.isDistributor,
          'header-table-cell': this.isAgentData,
          'sortable': column.sortable,
          'first-column': index === 0,
          'sticky-columns': index === 0 || index === this.columns.length -1,
        };
      };
    },
  },
  created() {
    this.mode = getMode();
    this.emitter.on("mode-change", (data) => {
      this.mode = data.mode;
    });
  },
  methods: {
    nextPage() {
      if (this.page.start < this.page.totalPages) {
        this.page.start++;
        this.$emit("pagination", this.page);
      }
    },
    prevPage() {
      if (this.page?.start > 1) {
        this.page.start--;
        this.$emit("pagination", this.page);
      }
    },
    sortByColumn(columnKey) {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.page.attribute = columnKey;
      this.page.order_by = this.sortOrder;
      this.$emit("pagination", this.page);
    },
    formattedDate(param) {
      const date = new Date(param);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    rowSelected(data) {
      this.isSelected = true;
      console.log('data: ', data);
      if (this.isDistributor) {
        const accounts = data.accounts;
        const dataArray = accounts.map(item => item.account);
        setAccounts(dataArray);
        this.$router.push('/accounts');
      } else {
        setAccountObject(data);
        this.$router.push({ path: "/accounts/" + data.unique_id });
      }
    },
    goBack() {
      if (this.isAccount) {
        this.$router.push('/distributors');
      } else {
        removeAccountObject();
        this.$router.push('/accounts');
      }
      
    },
    calculateSubtotal(columnKey) {
      console.log('ck: ', columnKey);
      return this.data.reduce((total, item) => total + item[columnKey], 0);
    },

    filterResults() {
      if (this.startDate && this.endDate) {
        const dates = {
          start_date: this.startDate,
          end_date : this.endDate,
        }
        this.$emit("filterByDates", dates);
      } else if (!this.startDate) {
        this.notify('Start Date is misisng');
      } else {
        this.notify('End Date is missing');
      }
    },
    clearFilters() {
      this.startDate = "";
      this.endDate = "";
      this.$emit("clearFilter");
    },
  },
};
</script>

<style scoped lang="scss">

.back-and-filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}
.email-and-filters {
  width: 100%;
}
.back {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.start-end-dates {
  display: flex;
  color: white;
  height: 5vh;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
.start-date, .end-date {
  display: flex;
  margin-left: 0.5vw;
}
p {
  color: white;
  margin: 0vh;
  display: flex;
  align-items: center;
  width: max-content;
}

.my-picker-class{
  border: none !important;
  border-bottom: 1px solid #F26F31 !important;
  width: 6vw !important;
  border-radius: 3px !important;
  background-color: transparent !important;
}
.agent-top-div {
  width: 100%;
  // display: flex;
  // justify-content: space-around;
  // align-items: center;
  margin-top: -7px;
}

.agent-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 5px;
}
.agent-email {
  // height: 5vh;
  // display: flex;
  font-size: larger;
  font-weight: bolder;
  align-items: center;

}

.rotateimg180 {
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  height: 18px;
}
.auth-img {
  height: 25px;
}
.arrow {
  height: 20px;
  margin: 5px 5px -3px 5px;
}

.arrow-down {
  transform: rotate(180deg);
}
.dynamic-table {
  display: flex;
  flex-direction: column;
  // margin: 50px;
  // min-width: 600px;
  border-radius: 5px;
  // overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  // height: 59%;
  // max-width: 100%;
  // overflow-x: auto;
  // max-width: 800px;
  overflow-y: hidden;
  overflow-x: auto;
  > div{
    min-width: max-content;
  }
}

.table-header {
  display: flex;
  background: linear-gradient(
    60deg,
    rgb(84, 58, 183) 0%,
    rgb(0, 172, 193) 100%
  );
  font-weight: bold;
  padding: 10px;

  .table-cell {
    font-size: larger;
    font-weight: bolder;
  }
  .header-table-cell {
    font-size: larger;
    font-weight: bolder;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.table-cell {
  padding: 10px;
  width: 200px;
  min-width: 200px;
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.7px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-agent-cell {
  padding: 10px;
  width: 90px;
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.7px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.datepicker-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.sticky-columns {
  position: sticky;
}
.text-alignment {
  text-align: left;
}
.add-margin {
  margin-left: -4vw;
}

.delete-cell {
  width: 50px;
  display: flex;
  align-items: center;

  img {
    height: 30px;
    cursor: pointer;
  }
}
.arrow-cell {
  width: 30px;
  display: flex;
  align-items: center;

  img {
    height: 9px;
    cursor: pointer;
  }
}

.table-agent-cell:nth-child(1) {
  width: 250px;
  position: sticky;
}
.table-agent-cell:nth-child(n) {
  position: sticky;
}

.statuses {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.body-cont {
  display: flex;
  flex-direction: column;
  max-height: 295px;
  // max-height: calc(100vh - 496px);
  background: rgba(255, 255, 255, 0.0784313725);
  padding: 15px 0;
  overflow-y: auto;
}
.table-body {
  display: flex;
  flex-direction: column;
  color: #29293e;
  // height: 480px;
  // max-height: calc(100vh - 400px);
  // overflow-y: auto;
  // background: #ffffff14;
  // padding: 15px 0;

  .table-row {
    justify-content: unset;
    &:hover {
      background: #ffffff26;
      cursor: pointer;
    }
  }
}

.table-row {
  display: flex;
  color: white;
  border-bottom: 2px solid #0f0f3f;
  margin: 0 0.5vw;
}

.subtotal-row {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: #ffffff14;
  display: flex;
  color: white;
  padding: 0 0.5vw;
  margin-top: 0.5vh;
  font-weight: bolder;
}
.submit-button {
  color: white;
  padding: 5px;
  border: none;
  cursor: pointer;
  margin-left: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  border-radius: 4px;
  background-color: #242460;
}

.submit-button:disabled {
  /* background-color: rgb(0, 172, 193, 0.11); */
  background-color: #242460;
  pointer-events: none;
}
.submit-button:hover {
    background-color: rgba(255, 255, 255, 0.11);
}
.gl-color {
  color: #1c7cc9;
  font-weight: 600;
}
.dark-gl-color {
  color: #242460;
  font-weight: 600;
}

.alternate-row {
  color: #f0f0f0;
}
.disable-click {
  pointer-events: none;
}

.dark-mode {
  .table-header {
    background: linear-gradient(60deg, #25274d 0%, rgb(68 45 153) 100%);
  }

  .pagination {
    .btns,
    button {
      color: black !important;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background: linear-gradient(
    60deg,
    rgba(255, 255, 255, 0.539) 0%,
    rgb(247, 247, 247) 100%
  );

  .btn-box {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-left: 284px;
  }

  .rotate-img {
    rotate: 180deg;
  }

  .count {
    color: black !important;
    margin-top: 2px;

    span {
      font-weight: bold;
    }
  }
}

.pagination button {
  padding: 5px 10px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}

.pagination button[disabled] {
  color: white;
  cursor: not-allowed;
}

.filters {
  display: flex;
  padding: 3px 5px;
  margin: 0 10px;
  border-radius: 5px;

  div {
    display: flex;
    align-items: center;
    margin: 0 5px;
    font-weight: 600;

    input {
      margin-right: 5px;
    }

    label,
    input {
      cursor: pointer;
    }
  }
}

.tag {
  padding: 3px 4px;
  border-radius: 4px;
  width: fit-content;
  text-transform: capitalize;
  min-width: 120px;
  color: white;
  font-weight: 600;
}

.new-tag {
  background-color: #0d6efd;
}

.successful-tag {
  background-color: #20c997;
}

.skipped-tag {
  background-color: #0d6efd;
}

.failed-tag {
  background-color: #87212b;
}

.new {
  color: #2288f5;
}

.successful {
  color: #28a745;
}

.skipped {
  color: #0d6efd;
}

.failed {
  color: #dc3545;
}

.all {
  background-image: linear-gradient(
    60deg,
    rgb(84, 58, 183) 0%,
    rgb(0, 172, 193) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Add styles for the sortable column headers */
.table-cell.sortable {
  cursor: pointer;
  user-select: none;
}

/* Style the arrow icon in the column header */
.table-cell span i {
  margin-left: 4px;
}
.table-agent-cell span i {
  margin-left: 4px;
  // text-align: left;
}

/* Style the arrow icon based on the sort order */
.table-cell span i.fa-arrow-up::before {
  content: "\f062";
  /* Up arrow icon */
}

.table-cell span i.fa-arrow-down::before {
  content: "\f063";
  /* Down arrow icon */
}

.loader-c {
  display: flex;
  flex-direction: column;

  h3 {
    color: white;
  }

  div {
    transform: rotate(180deg);
  }
}

.vibrating-element {
  animation: vibrate ease-in-out 1s infinite;
  height: 100px;
  opacity: 0.8;
}

@keyframes vibrate {
  0% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

@media (max-width: 568px) {
  .dynamic-table {
    overflow-y: hidden;
  }
  .table-header{
    .table-cell {
      font-size: medium;
    }
  }

  .table-cell {
    min-width: 100px;
    width: 100px;
  }
  .body-cont {
    height: 100%;
    max-height: calc(100vh - 289px);
  }

  .agent-top-div {
    height: 10%;
  }

  .back-and-filters{
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 80px;
  }
  .start-end-dates{
    flex-wrap: wrap;
    gap: 12px;
    justify-content: flex-start;
  }
  .arrow-cell {
    margin-left: 7px;
  }
}

</style>